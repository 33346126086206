export const colors = {
  // main colors
  primary: '#fff',

  secondary: '#14161f',
  secondary_light: '#35373f',
  secondary_005: 'rgba(255,255,255,0.05)',
  secondary_010: 'rgba(255, 255, 255, 0.1)',
  secondary_050: 'rgba(255, 255, 255, 0.5)',
  secondary_075: 'rgba(255, 255, 255, 0.75)',

  // theme colors
  alpha: '#F4282D',

  // state colors
  success: '#4a9666',
  success_light: 'rgba(76, 175, 80, 0.4)',
  success_alt: '#37d773',
  warning: '#f87907',
  warning_light: 'rgba(248, 121, 7, 0.4)',
  error: '#a83733',
  error_alt: '#ea4034',
  error_light: 'rgba(235, 65, 53, 0.4)',

  // shades
  gray_050: '#F7F7F7',
  gray_100: '#E0E0E0',
  gray_500: '#808080',
}
