import axios from 'axios'

type Response = {
  version: string
  capiUrl: string
}

export const getBackofficeInfo = async (url: string) => {
  const res = await axios.get<Response>(`${url}/version.json`, {
    timeout: 10 * 1000,
  })
  return res.data
}
