import { matchPath } from 'react-router-dom'

const privateRoutes = {
  index: '/',
}

const publicRoutes = {}

const externalRoutes = {}

const routes = { ...privateRoutes, ...publicRoutes, external: externalRoutes }

export const isRoutePrivate = (path: string | readonly string[]) => {
  if (typeof path === 'string') {
    return !!Object.values(privateRoutes).find((r) => !!matchPath(r, path))
  } else {
    return path.some(
      (it) => !!Object.values(privateRoutes).find((r) => !!matchPath(r, it))
    )
  }
}

export const getRouteIndex = (route: string) => {
  return Object.values(routes)
    .findIndex((it) => {
      return it === route
    })
    .toString()
}

export default routes
