import React from 'react'
import { useTranslation } from 'react-i18next'
import { projects } from 'src/data/projects'
import SingleRow from './SingleRow'

import * as S from './styled'

const DashboardPage: React.FC = () => {
  const [t] = useTranslation()

  return (
    <S.Wrap>
      <S.Title>{t('title')}</S.Title>
      {projects.map((project) => (
        <S.ProjectWrap key={project.customer}>
          <S.SubTitle>{project.customer}</S.SubTitle>
          <S.Table>
            <thead>
              <tr>
                <th></th>
                <th>Casino</th>
                <th>Backoffice</th>
                <th>Capi</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {project.list.map((website) => (
                <SingleRow data={website} key={website.name} />
              ))}
            </tbody>
          </S.Table>
        </S.ProjectWrap>
      ))}
    </S.Wrap>
  )
}

export default DashboardPage
