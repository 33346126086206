import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import common_cs from 'src/static/locales/cs/common.json'
import config from 'src/config'
import { appConfig } from 'src/constants/appConfig'

// define app namespaces
export enum NS {
  common = 'common',
}

export const getCurrentLanguageWithoutLocale = () => {
  return i18next.language.split('-')[0]
}

// map languages to json files (namespaces)
export const resources = {
  cs: {
    common: common_cs,
  },
}
export const defaultNS = 'common'

// export only languages, ["cs", "en", ... ]
export const appLanguagesArray: string[] = appConfig.languages

// setup i18next
i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: config.REACT_APP_ENV === 'dev',
    interpolation: { escapeValue: false }, // React already does escaping
    lng: appLanguagesArray[0],
    fallbackLng: appLanguagesArray[0],
    defaultNS: defaultNS,
    fallbackNS: defaultNS,
    resources: resources,
    load: 'languageOnly',
    returnEmptyString: false,
  })

export default i18next
