import styled from 'styled-components'

export const Wrap = styled.div`
  padding: 32px;
`

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 32px;
`

export const ProjectWrap = styled.div`
  margin-bottom: 48px;
`

export const SubTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  background: #e4e4e4;
  padding: 16px;
  border-radius: 4px;
`

export const Table = styled.table`
  border-collapse: collapse;
  margin: 0 16px;

  tr {
    border-bottom: 1px solid #f2f2f2;
  }

  th,
  td {
    font-weight: unset;
    padding-right: 10px;
  }

  th {
    padding-top: 21px;
    padding-bottom: 21px;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    color: #333;
    line-height: 1.4;
    text-transform: uppercase;
  }

  td {
    font-size: 15px;
    line-height: 1.4;

    width: 27%;

    &:first-child {
      width: 19%;
    }
  }
`
