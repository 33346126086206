import styled from 'styled-components'
import { colors } from '.'

export const fontFamily = {
  primary: 'Poppins',
}

export const fontWeight = {
  medium: '500',
  semiBold: '600',
  bold: '700',
}

export const H1 = styled.h1`
  font-size: 2.6rem;
  line-height: normal;
  letter-spacing: 0.11rem;
  font-weight: ${fontWeight.bold};
  color: ${colors.secondary};
`
