import React from 'react'
interface IState {
  error?: Error | null
  info?: {
    componentStack?: Object
  } | null
}

type Props = React.PropsWithChildren<{}>

class ErrorBoundary extends React.Component<Props, IState> {
  public state: IState = { error: null, info: null }
  private unlisten = () => {}

  componentDidMount() {
    // this.unlisten = this.props.history.listen((location, action) => {
    //   if (this.state.error) {
    //     this.setState({ error: null })
    //   }
    // })
  }

  componentWillUnmount() {
    this.unlisten()
  }

  public componentDidCatch(error: Error | null, errorInfo: any) {
    this.setState({ error, info: errorInfo })
    console.error(errorInfo)
    console.log(errorInfo)
  }

  public render() {
    if (this.state.error) {
      // render fallback UI
      return 'There was an error'
    } else {
      // when there's not an error, render children untouched
      return this.props.children
    }
  }
}

// export default withRouter(ErrorBoundary)
export default ErrorBoundary
