import styled, { css } from 'styled-components'

export const StyledTd = styled.td<{ isError?: boolean }>`
  vertical-align: middle;

  ${({ isError }) =>
    isError &&
    css`
      color: red;
      font-weight: 800;

      span:first-child {
        text-transform: uppercase;
      }
    `}

  a {
    color: inherit;
    text-decoration: none;
    display: block;

    padding-top: 16px;
    padding-bottom: 16px;

    &:hover {
      text-decoration: underline;
      text-transform: none;
    }
  }

  svg {
    height: 20px;
    width: 20px;
  }

  &:last-child {
    padding-left: 10px;
  }

  &:not(:last-child):hover {
    background: #f7f7f7;
  }
`

export const SmallSpan = styled.span`
  font-size: 12px;
  display: block;
`
