import React, { useEffect, PropsWithChildren } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { isRoutePrivate } from 'src/constants/routes'
import ErrorBoundary from 'src/containers/ErrorBoundary'
import { checkIfIsAuthenticated } from 'src/utils/authHelpers'

type Props = PropsWithChildren<{
  title?: string // option to change page title
}>

const RedirectToAuthPage = () => {
  useEffect(() => {
    window.location.href = '/auth.html'
  }, [])

  return null
}

const AppRoute: React.FC<Props> = ({ children, title }) => {
  const [t] = useTranslation()
  const { pathname } = useLocation()

  useEffect(() => {
    document.title = `${t('pageTitle')}${title ? ` - ${title}` : ''}`
  }, [title, t])

  const isAuthenticated = checkIfIsAuthenticated()

  const isPrivate = !pathname ? false : isRoutePrivate(pathname)

  return isPrivate && !isAuthenticated ? (
    <RedirectToAuthPage />
  ) : (
    <ErrorBoundary>{children}</ErrorBoundary>
  )
}

export default AppRoute
