import React from 'react'
import {
  BrowserRouter as Router,
  Navigate,
  Routes,
  Route,
} from 'react-router-dom'

import routes from 'src/constants/routes'
import AppRoute from 'src/containers/AppRoute'

// pages
import DashboardPage from 'src/pages/DashboardPage'
import Footer from './Footer'

const App: React.FC = () => {
  return (
    <React.Fragment>
      <Router>
        <Routes>
          {/* Public Pages */}
          <Route
            path={routes.index}
            element={
              <AppRoute>
                <DashboardPage />
              </AppRoute>
            }
          />
          <Route path='*' element={<NoMatch />} />
        </Routes>
      </Router>
      <Footer />
    </React.Fragment>
  )
}

// 404 route
export const NoMatch: React.FC = () => {
  return <Navigate to={routes.index} />
}

export default App
