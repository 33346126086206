import { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'

import GlobalStyles from 'src/styles/globalStyles'
import * as serviceWorker from 'src/serviceWorker'
import i18n from './i18n'

import App from 'src/components/App'

import config from './config'

console.log(`App version: ${config.REACT_APP_VERSION}`)

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <I18nextProvider i18n={i18n}>
    <Suspense fallback='loading'>
      <App />
      <GlobalStyles />
    </Suspense>
  </I18nextProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
