import config from 'src/config'
import { CookiesKeys, deleteCookie, getCookie } from './cookieUtils'

export const checkIfIsAuthenticated = () => {
  return config.REACT_APP_AUTH_PASSWORD
    ? getCookie(CookiesKeys.AUTH_TOKEN) === config.REACT_APP_AUTH_PASSWORD
    : true
}

export const logoutUser = () => {
  deleteCookie({ name: CookiesKeys.AUTH_TOKEN })
}
