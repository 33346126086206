export enum CookiesKeys {
  AUTH_TOKEN = 'auth_token',
}

export const parseCookie = (cookieString: string) =>
  cookieString.split(';').reduce((res, c) => {
    const [key, val] = c.trim().split('=').map(decodeURIComponent)
    try {
      return Object.assign(res, { [key]: JSON.parse(val) })
    } catch (e) {
      return Object.assign(res, { [key]: val })
    }
  }, {} as { [key: string]: string })

export const getCookie = (name: string) => parseCookie(document.cookie)[name]

export const setCookie = ({
  name,
  value,
  expiration,
}: {
  name: string
  value: string
  expiration?: Date
}) => {
  document.cookie = `${name}=${value}; ${
    expiration ? `expires=${expiration.toUTCString()}; ` : ''
  }path=/;`
}

export const deleteCookie = ({ name }: { name: string }) => {
  document.cookie = `${name}=xxx; expires= Thu, 01 Jan 1970 00:00:00 GMT; path=/;`
}
