export type Website = {
  name: string
  casinoUrl: string
  backofficeUrl: string
  kioskUrl?: string
}

export type Project = {
  customer: string
  list: Website[]
}

export const projects: Project[] = [
  {
    customer: 'cbdata-cz',
    list: [
      {
        name: 'dev',
        casinoUrl: 'https://cbdata-dev-onling.cbdata.cz',
        backofficeUrl: 'https://cbdata-dev-backoffice.cbdata.cz',
      },
      {
        name: 'test',
        casinoUrl: 'https://cbdata-test-onling.cbdata.cz',
        backofficeUrl: 'https://cbdata-test-backoffice.cbdata.cz',
      },
      {
        name: 'uat',
        casinoUrl: 'https://cbdata-uat-onling.cbdata.cz',
        backofficeUrl: 'https://cbdata-uat-backoffice.cbdata.cz',
      },
    ],
  },
  {
    customer: 'cbdata-sk',
    list: [
      {
        name: 'dev',
        casinoUrl: 'https://cbdata-dev-onling.cbdata.sk',
        backofficeUrl: 'https://cbdata-dev-backoffice.cbdata.sk',
      },
      {
        name: 'test',
        casinoUrl: 'https://cbdata-test-onling.cbdata.sk',
        backofficeUrl: 'https://cbdata-test-backoffice.cbdata.sk',
      },
      {
        name: 'uat',
        casinoUrl: 'https://cbdata-uat-onling.cbdata.sk',
        backofficeUrl: 'https://cbdata-uat-backoffice.cbdata.sk',
      },
    ],
  },
  {
    customer: 'slovakgames-sk',
    list: [
      {
        name: 'test',
        casinoUrl: 'https://slovakgames-test-onling.cbdata.sk',
        backofficeUrl: 'https://slovakgames-test-backoffice.cbdata.sk',
      },
      {
        name: 'uat',
        casinoUrl: 'https://slovakgames-uat-onling.cbdata.sk',
        backofficeUrl: 'https://slovakgames-uat-backoffice.cbdata.sk',
      },
      {
        name: 'prod',
        casinoUrl: 'https://g4g.sk',
        backofficeUrl: 'https://backoffice.g4g.sk',
      },
    ],
  },
  {
    customer: 'modplay-cz',
    list: [
      {
        name: 'test',
        casinoUrl: 'https://modplay-test-onling.cbdata.cz',
        backofficeUrl: 'https://modplay-test-backoffice.cbdata.cz',
      },
      {
        name: 'uat',
        casinoUrl: 'https://modplay-uat-onling.cbdata.cz',
        backofficeUrl: 'https://modplay-uat-backoffice.cbdata.cz',
      },
      {
        name: 'prod',
        casinoUrl: 'https://vsadahrej.cz',
        backofficeUrl: 'https://backoffice.vsadahrej.cz',
      },
    ],
  },
  {
    customer: 'magicplanet-cz',
    list: [
      {
        name: 'test',
        casinoUrl: 'https://magic-test-onling.cbdata.cz',
        backofficeUrl: 'https://magic-test-backoffice.cbdata.cz',
      },
      {
        name: 'uat',
        casinoUrl: 'https://magic-uat-onling.cbdata.cz',
        backofficeUrl: 'https://magic-uat-backoffice.cbdata.cz',
      },
      {
        name: 'prod',
        casinoUrl: 'https://magicplanet.cz',
        backofficeUrl: 'https://backoffice.magicplanet.cz',
      },
    ],
  },
  {
    customer: '69games-cz',
    list: [
      {
        name: 'test',
        casinoUrl: 'https://69games-test-onling.cbdata.cz',
        backofficeUrl: 'https://69games-test-backoffice.cbdata.cz',
      },
      {
        name: 'uat',
        casinoUrl: 'https://69games-uat-onling.cbdata.cz',
        backofficeUrl: 'https://69games-uat-backoffice.cbdata.cz',
      },
      {
        name: 'prod',
        casinoUrl: 'https://69games.cz',
        backofficeUrl: 'https://backoffice.69games.cz',
      },
    ],
  },
  {
    customer: '3eprojekt-cz',
    list: [
      {
        name: 'test',
        casinoUrl: 'https://3eprojekt-test-onling.cbdata.cz',
        backofficeUrl: 'https://3eprojekt-test-backoffice.cbdata.cz',
      },
      {
        name: 'uat',
        casinoUrl: 'https://3eprojekt-uat-onling.cbdata.cz',
        backofficeUrl: 'https://3eprojekt-uat-backoffice.cbdata.cz',
      },
      {
        name: 'prod',
        casinoUrl: 'https://tokyo.cz',
        backofficeUrl: 'https://3eprojekt-backoffice.cbdata.cz',
      },
    ],
  },
  {
    customer: 'bonverwin-cz',
    list: [
      {
        name: 'test',
        casinoUrl: 'https://bonver-test-onling.cbdata.cz',
        backofficeUrl: 'https://bonver-test-backoffice.cbdata.cz',
      },
      {
        name: 'uat',
        casinoUrl: 'https://bonver-uat-onling.cbdata.cz',
        backofficeUrl: 'https://bonver-uat-backoffice.cbdata.cz',
      },
      {
        name: 'prod',
        casinoUrl: 'https://www.bonvercasino.cz',
        backofficeUrl: 'https://bonverwin-backoffice.cbdata.cz',
      },
    ],
  },
  {
    customer: 'gameworld-sk',
    list: [
      {
        name: 'test',
        casinoUrl: 'https://gameworld-test-onling.cbdata.sk',
        backofficeUrl: 'https://gameworld-test-backoffice.cbdata.sk',
      },
      {
        name: 'uat',
        casinoUrl: 'https://gameworld-uat-onling.cbdata.sk',
        backofficeUrl: 'https://gameworld-uat-backoffice.cbdata.sk',
      },
      {
        name: 'prod',
        casinoUrl: 'https://gameworldbet.sk',
        backofficeUrl: 'https://backoffice.gameworldbet.sk',
      },
    ],
  },
  {
    customer: 'eurogold-sk',
    list: [
      {
        name: 'uat',
        casinoUrl: 'https://eurogold-uat-onling.cbdata.sk/',
        backofficeUrl: 'https://eurogold-uat-backoffice.cbdata.sk',
      },
    ],
  },
  {
    customer: 'nerotrade-cz',
    list: [
      {
        name: 'prod',
        casinoUrl: '',
        backofficeUrl: 'https://nerotrade-backoffice.cbdata.cz',
      },
    ],
  },
  {
    customer: 'bigwin-cz',
    list: [
      {
        name: 'prod',
        casinoUrl: '',
        backofficeUrl: 'https://bigwin-backoffice.cbdata.cz',
      },
    ],
  },
  {
    customer: 'companiaplus-cz',
    list: [
      {
        name: 'prod',
        casinoUrl: '',
        backofficeUrl: 'https://companiaplus-backoffice.cbdata.cz',
      },
    ],
  },
  {
    customer: 'autogames-cz',
    list: [
      {
        name: 'prod',
        casinoUrl: '',
        backofficeUrl: 'https://autogames-backoffice.cbdata.cz',
      },
    ],
  },
  {
    customer: 'merkurcasino-cz',
    list: [
      {
        name: 'prod',
        casinoUrl: '',
        backofficeUrl: 'https://merkurcasino-backoffice.cbdata.cz',
      },
    ],
  },
  {
    customer: 'showroom-cz',
    list: [
      {
        name: 'uat',
        casinoUrl: '',
        backofficeUrl: 'https://showroom-uat-backoffice.cbdata.cz',
      },
    ],
  },
  {
    customer: 'palasino-cz',
    list: [
      {
        name: 'uat',
        casinoUrl: 'https://palasino-uat-onling.cbdata.cz',
        backofficeUrl: 'https://palasino-uat-backoffice.cbdata.cz',
      },
    ],
  },
]
