enum requiredVariables {
  'REACT_APP_VERSION' = 'REACT_APP_VERSION', // 1.2.3
  'REACT_APP_ENV' = 'REACT_APP_ENV',
  'REACT_APP_AUTH_PASSWORD' = 'REACT_APP_AUTH_PASSWORD',
}

type RequiredVariables = keyof typeof requiredVariables
type Config = Readonly<{ [key in RequiredVariables]: string }>

// add extra values that are not in .env files
const extraConfig: Readonly<{ [key in requiredVariables]?: string }> = {}

// ensure that all of the env vars are provided
const ensureConfig = (env: NodeJS.ProcessEnv): Config => {
  // concat
  const config = { ...env, ...extraConfig }

  return Object.values(requiredVariables).reduce((acc, key) => {
    // Filters index entries from enum.
    if (typeof key === 'number') {
      return acc
    }

    if (config[key] === undefined) {
      throw new Error(`Missing '${key}' environment variable.`)
    }

    return { ...acc, [key]: config[key] }
  }, ({} as any) as Config)
}

export default ensureConfig(process.env)
