import React, { useEffect, useState } from 'react'

import { getBackofficeInfo } from 'src/api/backofficeApi'
import { getCasinoInfo } from 'src/api/casinoApi'
import { getCapiVersion } from 'src/api/capiApi'
import Cross from 'src/components/SVG/Cross'
import Check from 'src/components/SVG/Check'
import Warning from 'src/components/SVG/Warning'
import { Website } from 'src/data/projects'

import * as S from './styled'
import { compareVersions } from 'src/utils/versionUtils'

type Props = {
  data: Website
}

type InstanceState = {
  url: string
  version: string | undefined
  capi: string | undefined
  state?: 'ERROR' | 'OK'
}

const SingleRow: React.FC<Props> = ({ data }) => {
  const [casinoState, setCasinoState] = useState<InstanceState>({
    url: data.casinoUrl || data.kioskUrl!,
    version: undefined,
    capi: undefined,
  })
  const [backofficeState, setBackofficeState] = useState<InstanceState>({
    url: data.casinoUrl,
    version: undefined,
    capi: undefined,
  })
  const [capiState, setCapiState] = useState<Omit<InstanceState, 'capi'>>({
    url: '',
    version: undefined as string | undefined,
  })
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchCapiVersion = async (capiUrl: string) => {
      try {
        if (!capiUrl) {
          setCapiState({
            url: capiUrl,
            version: 'unknown version',
            state: 'ERROR',
          })
          return
        }
        const capiVersion = await getCapiVersion(capiUrl)
        setCapiState({
          url: capiUrl,
          version: capiVersion,
          state: 'OK',
        })
      } catch (e) {
        setCapiState({
          url: capiUrl,
          version: 'error',
          state: 'ERROR',
        })
      }
    }

    const fetchCasinoVersion = async () => {
      const frontentUrl = data.casinoUrl || data.kioskUrl!
      try {
        if (!frontentUrl) {
          setCasinoState({
            url: '',
            version: '---',
            capi: undefined,
            state: 'OK',
          })
        }
        // 1. get frontend config
        const res = data.casinoUrl
          ? await getCasinoInfo(frontentUrl)
          : data.kioskUrl
          ? await getBackofficeInfo(frontentUrl)
          : undefined
        if (!res) {
          return
        }
        setCasinoState({
          url: frontentUrl,
          version: res.version,
          capi: res.capiUrl,
          state: 'OK',
        })
        return res
      } catch (e) {
        setCasinoState({
          url: frontentUrl,
          version: 'unknown version',
          capi: undefined,
          state: 'ERROR',
        })
      }
    }

    const fetchBackofficeVersion = async () => {
      try {
        if (!data.backofficeUrl) {
          setBackofficeState({
            url: '',
            version: '---',
            capi: undefined,
            state: 'OK',
          })
          return
        }
        const res = await getBackofficeInfo(data.backofficeUrl)
        setBackofficeState({
          url: data.backofficeUrl,
          version: res.version,
          capi: res.capiUrl,
          state: 'OK',
        })
        return res
      } catch (e) {
        setBackofficeState({
          url: data.backofficeUrl,
          version: 'unknown version',
          capi: undefined,
          state: 'ERROR',
        })
      }
    }

    const fetchAll = async () => {
      const [casinoResult, backofficeResult] = await Promise.all([
        fetchCasinoVersion(),
        fetchBackofficeVersion(),
      ])
      const capiUrl = casinoResult?.capiUrl || backofficeResult?.capiUrl || ''
      await fetchCapiVersion(capiUrl)
      setIsLoading(false)
    }

    fetchAll()
  }, [data])

  const isFrontendError = casinoState.state === 'ERROR'
  const isBackofficeError = backofficeState.state === 'ERROR'
  const isCapiError = capiState.state === 'ERROR'
  const isCapiVersionError = isFrontendError || isBackofficeError || isCapiError

  // check capi versions
  const casinoHasWrongCapi = !!(
    capiState.url &&
    casinoState.capi &&
    casinoState.capi !== capiState.url
  )
  const backofficeHasWrongCapi = !!(
    capiState.url &&
    backofficeState.capi &&
    backofficeState.capi !== capiState.url
  )

  const isError =
    isFrontendError ||
    isBackofficeError ||
    casinoHasWrongCapi ||
    backofficeHasWrongCapi

  const { casinoVersionError, backofficeVersionError, capVersionError } =
    compareVersions({
      casino: casinoState.version,
      backoffice: backofficeState.version,
      cap: capiState.version,
    })

  return (
    <tr>
      <S.StyledTd>{data.name}</S.StyledTd>

      <S.StyledTd
        isError={isFrontendError || casinoHasWrongCapi || casinoVersionError}
      >
        <a href={casinoState.url} target='_blank' rel='noopener noreferrer'>
          <span>
            {casinoState.version}
            {casinoHasWrongCapi ? (
              <>
                <br />
                CAPI url does not match!
              </>
            ) : null}
            {casinoVersionError ? (
              <>
                <br />
                Casino does not have the correct version
              </>
            ) : null}
          </span>
          <S.SmallSpan>{casinoState.url}</S.SmallSpan>
        </a>
      </S.StyledTd>

      <S.StyledTd
        isError={
          isBackofficeError || backofficeHasWrongCapi || backofficeVersionError
        }
      >
        {backofficeState.url ? (
          <a
            href={backofficeState.url}
            target='_blank'
            rel='noopener noreferrer'
          >
            <span>
              {backofficeState.version}
              {backofficeHasWrongCapi ? (
                <>
                  <br />
                  CAPI url does not match!
                </>
              ) : null}
            </span>
            {backofficeVersionError ? (
              <>
                <br />
                BO does not have the correct version
              </>
            ) : null}
            <S.SmallSpan>{backofficeState.url}</S.SmallSpan>
          </a>
        ) : (
          <span>{backofficeState.version}</span>
        )}
      </S.StyledTd>

      <S.StyledTd isError={isCapiError || capVersionError}>
        <span>
          {capiState.version}
          {capVersionError ? (
            <>
              <br />
              CAP does not have the correct version
            </>
          ) : null}
        </span>
        <S.SmallSpan>{capiState.url}</S.SmallSpan>
      </S.StyledTd>

      <S.StyledTd>
        {isLoading ? (
          ''
        ) : isError ? (
          <Cross />
        ) : isCapiVersionError ||
          casinoVersionError ||
          backofficeVersionError ||
          capVersionError ? (
          <Warning />
        ) : (
          <Check />
        )}
      </S.StyledTd>
    </tr>
  )
}

export default SingleRow
