export const compareVersions = ({
  casino,
  backoffice,
  cap,
}: {
  casino: string | undefined
  backoffice: string | undefined
  cap: string | undefined
}) => {
  const casinoMajorVersion = casino?.split('.')[2]
  const backofficeMajorVersion = backoffice?.split('.')[2]
  const capMajorVersion = cap?.split('.')[2]

  const casinoMajorIntVersion = casinoMajorVersion
    ? parseInt(casinoMajorVersion)
    : 0
  const backofficeMajorIntVersion = backofficeMajorVersion
    ? parseInt(backofficeMajorVersion)
    : 0
  const capMajorIntVersion = capMajorVersion ? parseInt(capMajorVersion) : 0

  const maxVersion = [
    casinoMajorIntVersion,
    backofficeMajorIntVersion,
    capMajorIntVersion,
  ].reduce((acc, item) => Math.max(acc, item), 0)

  return {
    casinoVersionError:
      !!casinoMajorVersion && casinoMajorIntVersion < maxVersion,
    backofficeVersionError:
      !!backofficeMajorVersion && backofficeMajorIntVersion < maxVersion,
    capVersionError: !!capMajorVersion && capMajorIntVersion < maxVersion,
  }
}
