import axios from 'axios'

type Response = string

export const getCapiVersion = async (url: string) => {
  const res = await axios.post<Response>(
    `${url}/capi/Diagnostics/v1/Diagnostics/Version`,
    { timeout: 10 * 1000 }
  )
  return res.data
}
