import axios from 'axios'

type Response = {
  version: string
  capiUrl: string
}

export const getCasinoInfo = async (url: string) => {
  const res = await axios.get<Response>(`${url}/api`, { timeout: 10 * 1000 })
  return res.data
}
